import React, {Fragment, useEffect, useRef, useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3Auth  } from "@web3auth/modal";
import {createAccount, getBalance, cluster, getConnectionConfig, getUserSessionData, backend_api_url} from "./../constants";
import Badge from 'react-bootstrap/Badge';
import 'react-toastify/dist/ReactToastify.css';

const Header = (props: any) => {

    const [avatar, setAvatar] = useState<string>("/avatar.png");
    const searchInput = useRef<HTMLInputElement>(null);
    const [isOver, setIsOver] = useState(false);

    const closeOver = () => {
        setIsOver(true);
    }
    

    const checkLogin = () => {
        const userData = getUserSessionData();
        if(userData) {
            // window.history.replaceState(null, "Add Post", "/add");
            window.location.href = "/add";
        }else{
            loginHandler();
        }
    }

    const loadUserData = () => {
        const userData = getUserSessionData();
        if(userData) {
            props.setHasUserInfo(true);
                  
            props.setIsAdmin(userData.isam === 1 ? true : false);

            props.loadUnreadHandler();
            props.loadSolBalance();
            fetchUserData();
        }
        //console.log("Load User Data");
    }

    
    const fetchUserData = async (): Promise<any> => {
        
        const userData = getUserSessionData();

        const params = {
            token: userData.token,
            secret: userData.secret,
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        /*
        fetch(backend_api_url + 'api/v1/users/postings', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                      
                    if(data.userInfo.avatar) {
                        setAvatar(data.userInfo.avatar);
                        props.setAvatar(data.userInfo.avatar);
                    }
                }
            });
            */
	};


    const initWeb3Auth = async () => {

        const userData = getUserSessionData();
        if(userData) {
            props.setHasUserInfo(true);

            //loadUserData();
        }

        try {
            const vars = getConnectionConfig();
            
            const web3authData = new Web3Auth({
                clientId: vars.clientId as string,
                chainConfig: {
                    chainNamespace: CHAIN_NAMESPACES.SOLANA,
                    chainId: vars.clientId,
                    rpcTarget: vars.rpcTarget, // This is the public RPC we have added, please pass on your own endpoint while creating an app
                },
                  // in seconds.
                //sessionTime: 86400 * 7 
            });
    
            props.setWeb3auth(web3authData);
            await web3authData.initModal();
               
            
            
            if (web3authData.provider) {
                props.setProvider(web3authData.provider);
                loadUserData();
            }else{
                const web3Container = document.getElementById('w3a-container');
                web3Container.style.display = "block"; 
            }
            
        } catch (error) {
          console.error(error);
        }
        
    };

    useEffect(() => {
        loadUserData();
        initWeb3Auth();

        if(window.location.pathname === '/login') {
            checkLogin();
        }
	  }, []);
    
    const loginHandler = () => {
        setOpenMobile(false);

         props.loginWeb3Auth();
    } 
    
    

    const logoutHandler = async () => {
        localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE);
        props.setHasUserInfo(false);
        await props.web3auth.logout();
        //setProvider(null);
    }
    const homeHandler = () => {
        window.history.replaceState(null, "New Page Title", "/");
    }

    const submitHandler = (e) => {
        e.preventDefault();
        //console.log(searchInput.current.value);

        if(searchInput.current.value) {
            window.location.href = "/search/"+searchInput.current.value;
        }
    }

    const [openMobile, setOpenMobile] = useState(false);

    const toggleLinks = () => {
        setOpenMobile(!openMobile);
    }

    return (
        <Fragment>
        

<div id="Header">
		<img src="/images/icons/mobile-nav.png" onClick={toggleLinks} id="MobileNav" alt="" />  
		<div id="HeaderDiv">
		  <div className="logoDiv">
			<a className="logo" href="/" onClick={homeHandler}><img src="/images/logo.png" alt="ToyBot" title="ToyBot" width="120"/></a> 
		  </div>

		  <div className={'userLinks' + " " + (openMobile ? "open-mobile" : "") }>

          <a className='make-link desktop' target="_blank" href="/how-it-works">HOW IT WORKS</a>
          <a className='make-link desktop' target="_blank" href="/for-creators">FOR CREATORS</a>

            { 
               props.hasUserInfo && props.isAdmin && <a className='text-decoration-none'  href="/admin">ADMIN</a>
            }
            <a className='text-decoration-none' style={ {display: props.hasUserInfo ? '' : 'none' }} href="/account">ACCOUNT</a>
			<a className='text-decoration-none' style={ {display: props.hasUserInfo ? '' : 'none' }} href="/wallet">WALLET</a>
			{/* <a style={ {display: props.hasUserInfo ? '' : 'none' }} href="#" onClick={logoutHandler}>LOGOUT</a> */}
            <button style={ {display: props.hasUserInfo ? '' : 'none' }} className="buttonLink btn-logout" onClick={logoutHandler}>LOGOUT</button>
			
			{/* <a style={ {display: props.hasUserInfo ? 'none' : '' }} href="#" onClick={loginHandler}>SIGNUP</a>
			<a style={ {display: props.hasUserInfo ? 'none' : '' }} href="#" onClick={loginHandler}>LOGIN</a> */}

            

            <button style={ {display: props.hasUserInfo ? 'none' : '' }} className="buttonLink btn-logout" onClick={loginHandler}>SIGNUP</button>
            <button style={ {display: props.hasUserInfo ? 'none' : '' }} className="buttonLink btn-logout" onClick={loginHandler}>LOGIN</button>

            <a className='make-link mobile' target="_blank" href="/how-it-works">HOW IT WORKS</a>
            <a className='make-link mobile' target="_blank" href="/for-creators">FOR CREATORS</a>

			<a href="#close" onMouseOver={closeOver} className={"close-link"}  onClick={toggleLinks}>CLOSE</a>
		  </div>

		  <div className="searchBox" style={{"display" : "none"}}>

			<form name="frmSearch" id="frmSearch" method="get" onSubmit={submitHandler} >

			  <input type="image" src="/images/icons/search.png" id="BtnSearch" title=" Search " alt="" />	
			  <input type="text" name="Keywords" id="Keywords" className="textbox" placeholder="search" ref={searchInput} />

			</form>

		  </div> 

		  <div className="br"></div>

		 </div> 
	  </div>


         

        </Fragment>
    );
}
export default Header;