import React, {Fragment, useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import { backend_api_url, cluster, getCurrentWallet, getUserBalance, getUserSessionData, isShowLedgerInfo, isShowLedgerInfoNew, wait} from "./constants";
import { PublicKey , Keypair, LAMPORTS_PER_SOL,  Connection, clusterApiUrl } from "@solana/web3.js";
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentWalletPublicKey} from './constants';

import WalletLink from "./WalletLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { SystemProgram, Transaction } from '@solana/web3.js';

import { SolanaWallet } from "@web3auth/solana-provider";

    
import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import LedgerItem from "./LedgerItem"; 
import LedgerItemNew from "./LedgerItemNew";
 

const Purchases = (props : any) => {
  
    const [balance, setBalance] = useState<number>(0);
 
    const [ledgerJsx, setLedgerJsx] = useState<JSX.Element[] | null>([])
    const [ledgerMobileJsx, setLedgerMobileJsx] = useState<JSX.Element[] | null>([])
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);
 
    const [isProcessing, setIsProcessing] = useState(false);
 
      
 
    const reloadUserBalance = async() => {
      await getUserBalance(setBalance);

  }

  const loadAllPurchases = () => {
    setIsProcessing(true);

    const userData = getUserSessionData();
 
    let params = {
        token: userData ? userData.token : '',
        secret: userData ? userData.secret : '',
    }


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/users/get-txn', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 1) {
                
 
                renderTableData (data.txns); 
                
                setIsProcessing(false);

            }
        });
        
  }

  const renderTableData = (txns) => {

    setLedgerJsx( txns.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
    <tr>
        <td align="center">{info.date_created_display}</td>
        <td align="center">{info.isMint === 1 ? <Fragment>Mint <a target="_blank"   rel="noreferrer" href={'https://translator.shyft.to/address/'+info.address+'/?cluster='+cluster+'&compressed=true'}>NFT</a> - <a target="_blank" rel="noreferrer" href={"/collection/" + info.collectionAddress}>{info.collectionName}</a></Fragment> : info.type}</td>


        <td align="center">{info.amount}</td>

    </tr>
    </Fragment>) );

setLedgerMobileJsx( txns.map( (info, index) => <Fragment key={index + (new Date()).getTime()}> 
<table className="walletActivityTable"  style={{ "borderSpacing" : 0}}>
<tbody>
<tr>
    <td width="40%"><b>DATE</b></td>
    <td width="60%">{info.date_created_display	}</td>
</tr>
<tr>
    <td width="40%"><b>TYPE</b></td>
    <td width="60%">{info.isMint === 1 ? <Fragment>Mint <a target="_blank"   rel="noreferrer" href={'https://translator.shyft.to/address/'+info.address+'/?cluster='+cluster+'&compressed=true'}>NFT</a> - <a target="_blank" rel="noreferrer" href={"/collection/" + info.collectionAddress}>{info.collectionName}</a></Fragment> : info.type}</td>
</tr>
<tr>
    <td width="40%"><b>AMOUNT</b></td>
    <td width="60%">{info.amount}</td>
</tr> 
</tbody>
</table>
</Fragment>) );

  }
    useEffect(() => {
        if(!getUserSessionData()) { 
            window.location.href = "/";
        }else{
          //setIsProcessing(true);
          //checkTxn();

          const userData = getUserSessionData();
          if(userData) {
              setUsernameJsx(<a href={"/wallet"}>{userData.username}'s</a>);
              loadAllPurchases();
              reloadUserBalance();

          }
        }
    }, []);

    props.setClassInfo('walletActivityPg');
 
   


    return (
        <Fragment>

<div className="contents">

<h5 className="outer">{usernameJsx} &gt;&gt; Purchases</h5>

<div className="br"></div>

  <p><b>SOLs : {props.solBalance.toFixed(4)}<br/>Credits : ${balance.toFixed(2)}</b></p>

  <br/>

<div className="tblDesktop">
    {/* cellpadding="0" cellspacing="0" */}
<table className="walletActivityTable"  style={{ "borderSpacing" : 0}} >
  <tbody>
    <tr>
      <td align="center"><b>DATE</b></td>
      <td align="center"><b>TYPE</b></td>
      <td align="center"><b>AMOUNT</b></td>
    </tr>	

    {ledgerJsx}
 
  </tbody>
</table>
</div>

<div className="tblMobile">
  

    {ledgerMobileJsx}

    
</div>		

{isProcessing && <p className="bigger-font" style={{"textAlign": "center"}}>Processing...</p>}
 

</div>

        
        </Fragment>

    );
}

export default Purchases;