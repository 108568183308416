import React, {Fragment, useEffect, useState} from "react";
import { Link, useParams } from "react-router-dom";
import LatestListings from "./LatestListings";
import RecentlyMinted from "./RecentlyMinted";
import CollectionList from "./CollectionList";
import Slider from "react-slick";
import { backend_api_url, settingsHomeBannerSlider, settingsSlider } from "./constants";
import RecentlyJoined from "./RecentlyJoined";
import Modal from 'react-bootstrap/Modal';

const MainContainer = (props: any) => {
 
    const goToAddLink = (e) => {
        e.preventDefault();
        checkLogin();
    }

    const [mintError, setMintError] = useState("");
    const [mintErrorHeader, setMintErrorHeader] = useState("");
    const [showError, setShowError] = useState(false);
    const handleCloseError = () => setShowError(false);
    const handleShowError = () => setShowError(true);

    const [isUseCarousel, setIsUseCarousel] = useState(true);


    const checkLogin = () => {
        window.location.href = "/add";
    }

    const paramsPage = useParams();

    const activateAccount = () => {


    if(!paramsPage.id || !paramsPage.email)
        return;
        
 

    const params = {
        id: paramsPage.id,
        email: paramsPage.email
    } 
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    };
    fetch(backend_api_url + 'api/v1/users/activate', requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.status === 0) {
                //toast.error(data.message);
                setMintErrorHeader("Notification");
                setMintError(data.message);
                handleShowError();
                
            }else if(data.status === 1) {
                //show complete account
                //props.handleShowCompleteAccount();
 
                //toast.success("Account Activated Successfully");
                
                setMintErrorHeader("Notification");
                setMintError("Account Confirmed Successfully");
                handleShowError();

                const userInfo = {
                    token: data.token,
                    secret: data.secret,
                    username: data.username,
                    wallet: data.wallet,
                    isNew: 0
                } 
                localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(userInfo));

            }
        });
}

    useEffect(() => {
        activateAccount();

        let isMobile = false;
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
              navigator.userAgent
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
              navigator.userAgent.substr(0, 4)
            )
          )
            isMobile =  true;
        else 
            isMobile = false;
        
        setIsUseCarousel(isMobile ? false : true);
    }, [paramsPage.id, paramsPage.email]);

    props.setClassInfo("homePg");

    return (
        <Fragment>
            <div id="HomeBanner">
                <div id="HomeBannerDiv" className="slider">
                    <Slider  {...settingsHomeBannerSlider}>
                        <div className="homeBannerDetails">
                            <a href="/collection/8Z2b7eiTr42VcgN1oDBQE71Wz4ubqxdq47hRqFRUGXcj"><img alt="" src="/images/banner/banner0.png" /></a>
                        </div>
                        <div className="homeBannerDetails">
                            <a href="/collection/3fH5YWhS5ejhgwEsnp4hFNoQ47SrrpaeRhwVjDLzWW4H"><img alt="" src="/images/banner/banner1.png" /></a>
                        </div>
                        <div className="homeBannerDetails">
                            <a href="/collection/3wed36zuYT4pnJBRwGuwXFHxZi9NtmVtnpNrhxpES6dJ"><img alt="" src="/images/banner/banner2.jpg" /></a>
                        </div>
                        <div className="homeBannerDetails">
                            <a href="/collection/A9kjjCyz7N5gP7KegkbrfwJ1n2puU97Z9jkcAQaWL5zP"><img alt="" src="/images/banner/banner3.png" /></a>
                        </div>
                      

                    </Slider> 
                </div>
            </div>
            <div className="contents">
                
                {/* <div className="collections popularCollections">
                <h5>POPULAR COLLECTIONS</h5>

                <div className="collectionsDiv">
                    <CollectionList useCarousel={false}  type="popular" search=""></CollectionList>
                </div>	

                </div> */}

                <div className={isUseCarousel ? "collections latestCollections" : "collections ig latestCollections"}>
                    <h5>LATEST COLLECTIONS</h5> 
                    <div id="ImageSlider">
                        <div id="ImageSliderDiv" className="slider slider-minted">
                            <CollectionList useCarousel={isUseCarousel} type="latest" search=""></CollectionList>
                        </div>
                    </div>
 
            
                </div>

                <div className={isUseCarousel ? "collections recentlyMinted" : "collections ig recentlyMinted"}>
                    <h5>RECENTLY MINTED</h5>

                    <div id="ImageSlider">
                        <div id="ImageSliderDiv" className="slider slider-minted">
                            <RecentlyMinted candyMachineAddress='' username='' useCarousel={isUseCarousel}></RecentlyMinted>
                        </div>
                    </div>

                </div>

                <div className={isUseCarousel ? "collections recentlyMinted" : "collections ig recentlyMinted"}>
                    <h5>RECENTLY JOINED</h5>

                    <div id="ImageSlider">
                        <div id="ImageSliderDiv" className="slider slider-minted">
                            <RecentlyJoined type='' search="" useCarousel={isUseCarousel}></RecentlyJoined>
                        </div>
                    </div>

                </div>

            </div>
             
            <Modal  className="modal-processing"  show={showError} onHide={handleCloseError} backdrop="static">
                <Modal.Header closeButton={true}>
                <Modal.Title>{mintErrorHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                    <div style={{"textAlign": "center"}}>
                        {mintError}
                    </div>
    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> 

        </Fragment>
    );
}

export default MainContainer;