import React, {Fragment, useEffect, useState} from "react";
import { backend_api_url, getUserSessionData, cluster, settingsSlider, chunkArray, getCurrentWalletPublicKey, getConnectionConfig, getAssetsByOwner, getCollectionNameByAssetId, getAsset } from "./constants";
import { useConnection } from "@solana/wallet-adapter-react";
import { Keypair, ParsedAccountData  } from "@solana/web3.js";
import { Metaplex, PublicKey, keypairIdentity } from "@metaplex-foundation/js";
import axios from "axios";
import { toast } from "react-toastify";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import * as splToken from "@solana/spl-token";

import { Metadata } from "@metaplex-foundation/mpl-token-metadata";

const RecentlyMinted = (props: any) => {
 


    const [listingJsx, setListingJsx] = useState<JSX.Element[] | null>(null)
    const [num, setNum] = useState(0)


    const { connection } = useConnection();
   
    const [showPreview, setShowPreview] = useState(false);
    const handleClosePreview = () => setShowPreview(false);
    const handleShowPreview = () => setShowPreview(true);

    const [previewImageName, setPreviewImageName] = useState<JSX.Element>(null);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [symbol, setSymbol] = useState("");
    const [usernameJsx, setUsernameJsx] = useState<JSX.Element>(null);

    
    const [previewImageDesc, setPreviewImageDesc] = useState("");
    const [previewCollectionName, setPreviewCollectionName] = useState<JSX.Element>(null);
    const [selectedNftAddress, setSelectedNftAddress] = useState("");
    const [selectedNftMintAddress, setSelectedNftMintAddress] = useState("");
    const [propertiesJsx, setPropertiesJsx] = useState<JSX.Element[] | null>(null)
    const [allNfts, setAllNfts] = useState<any[]>(null)
 

    const [renderedElements, setRenderedElements] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const [isLoadDelay, setIsLoadDelay] = useState(false);

    
    useEffect(() => {
        const delay = 100; // Adjust the delay (in milliseconds) between rendering elements

        if(props!.isRefresh) {
            ;
        }else{
            const timer = setTimeout(() => {
            if (listingJsx && currentIndex < listingJsx.length) {
                setRenderedElements((prevElements) => [...prevElements, listingJsx[currentIndex]]);
                setCurrentIndex(currentIndex + 1);
            }
            }, delay);


            return () => clearTimeout(timer);
        }
    }, [currentIndex, listingJsx]);
     
    const viewNftsOwned = async (username) => {
        const params = {
            username: username
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/user-wallet', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    
                    //console.log(data.userInfo.primary_wallet);
                    loadAllNft(data.userInfo.primary_wallet, data.xMachines, data.pinnedNft);
                }
            });
    }

    const loadAllNft = async (wallet, hiddenMachines, pinnedNft) => {
        const keypair = Keypair.generate();
      
        const metaplex = new Metaplex(connection);
        metaplex.use(keypairIdentity(keypair));
      
        const owner = new PublicKey(wallet);
        //const allNFTs = await metaplex.nfts().findAllByOwner({ owner });
      
        const allNFTs = await getAssetsByOwner(wallet);

        //console.log(allNFTs);
        // return;
        var data = Object.keys(allNFTs).map((key) => allNFTs[key]);                                                                    
        let arr = [];
        let n = data.length;    
        
        //console.log(data);
        let isAdd = false;
        for (let i = 0; i < n; i++) {
            
            if(data[i].uri && data[i].uri.indexOf('http') >= 0) {
                
                let collectionName = ''; 

               

                if(hiddenMachines.length === 0 || !hiddenMachines.includes(data[i].collectionAddress)) {

                    if(data[i].type === 'cnft') {
                     
                        collectionName = await getCollectionNameByAssetId(data[i].assetId);
                    }

                    let address = data[i].id ? data[i].id : data[i].mintAddress;

                    if(props!.type === 'others') {
                        isAdd = false;
                        for(var x in pinnedNft) {
                            if(pinnedNft[x] === address) {
                                isAdd = true;
                            }
                        }
                        if(!isAdd) {
                            continue;
                        }
                    }

                    arr.push({
                        uri : data[i].uri,
                        address: data[i].mintAddress,
                        extraInfo: data[i],
                        collectionAddress: data[i].collectionAddress,
                        collectionName : collectionName

                    });
                }
            }
        }
        
        loadAllNftImages(arr)
    }

    const viewRecentlyMinted = async (address, username)  => {


        const params = {
            candy_machine_address: address,
            username: username,
            limit: props!.limit > 0 ? props!.limit : 0
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/recently-minted', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                    
                    

                    if(data.recentMints.length === 0) {
                        if(props!.type === 'mine') {
                            const noneJSX = [];
                            noneJSX.push(<Fragment>None yet</Fragment>);
                            setListingJsx(noneJSX);
                        }else if(props!.type === 'others') {
                            const noneJSX = [];
                            noneJSX.push(<Fragment>None yet</Fragment>);
                            setListingJsx(noneJSX);
                            props.setHideMinted(true);
                        }
                    }else{
                        displayRecentlyMinted(data.recentMints, data.xMachines);

                    }
                }
            });
            return;
        
    }

    const getMetadataInfo = async (metadata_uri) => {
        const requestOptions = {
            method: 'GET'
        };
        
        let dataInfo = fetch(metadata_uri, requestOptions)
            .then(response => response.json());
        return dataInfo;
    }
    const displayRecentlyMinted = async(recentMints : any, hiddenMachines) => {

        const keypair = Keypair.generate();
      
        const metaplex = new Metaplex(connection);
        metaplex.use(keypairIdentity(keypair));
        
        const mintedNftInfos = await Promise.all(
            recentMints.map(async (asset: any) => {
                
                if(asset.is_v2 === 1) {
                    const dataInfo = await getMetadataInfo(asset.metadata_uri);
                    dataInfo.uri = asset.metadata_uri;
                    dataInfo.collectionAddress = asset.collectionAddress;
                    dataInfo.is_v2 = 1;
                    dataInfo.address = asset.address;
                    dataInfo.collectionName = asset.collectionName;

                    //console.log(dataInfo);
                    return dataInfo;
                } else {
                    const mintAddress = new PublicKey(asset.address);
                    const dataInfo = await metaplex.nfts().findByMint({ mintAddress  });

                    
                    return dataInfo;
                }
            })
        );
         

        // console.log(mintedNftInfos);return;
        // return;
        var data = Object.keys(mintedNftInfos).map((key) => mintedNftInfos[key]);   
        let arr = [];
        let n = data.length;    
        for (let i = 0; i < n; i++) {
             
            let collectionName = ''; 

            if(data[i].is_v2 && data[i].collectionName) {
                    
                collectionName = data[i].collectionName;
            }else if(data[i].is_v2 && data[i].assetId) {
                    
                collectionName = await getCollectionNameByAssetId(data[i].assetId);
            }
 

            if(data[i].is_v2) {
                arr.push({
                    uri : data[i].uri,
                    address: data[i].address,
                    extraInfo: data[i],
                    collectionAddress: data[i].collectionAddress,
                    collectionName : collectionName

                });
            }else if(data[i].uri && data[i].uri.indexOf('http') >= 0) {
                if(hiddenMachines.length === 0 || !hiddenMachines.includes(data[i].collection.address.toBase58())) {
                    arr.push({
                        uri : data[i].uri,
                        address: data[i].address.toBase58(),
                        extraInfo: data[i],
                        collectionAddress: data[i].collection.address.toBase58(),
                        collectionName : collectionName

                    });
                }
            }
        } 
        // console.log('displayRecentlyMinted');
        // console.log(hiddenMachines);
        // console.log(arr);

        loadAllNftImages(arr)

    }

    const doRefreshScreen = () => {
        
        if(props.username) {
            viewNftsOwned(props.username);
        }else{
		    viewRecentlyMinted(props.candyMachineAddress, props.username);
        }
    }

    useEffect(() => {
        doRefreshScreen();
	  }, []);

      useEffect(() => {
        if(props!.isRefresh)
            setTimeout(doRefreshScreen, 1500);
	  }, [props!.isRefresh]);

      const getMetadataPDA = async(mint) => {
        const mintKey = new PublicKey(mint);
        try{
            const largestAccounts = await connection.getTokenLargestAccounts(mintKey);
            const largestAccountInfo = await connection.getParsedAccountInfo(
                largestAccounts.value[0].address  //first element is the largest account, assumed with 1 
            );
            let ownerAddress = ((largestAccountInfo.value.data as ParsedAccountData).parsed.info.owner);
     
    
            await displayOwner(ownerAddress);

        }catch (err: any) {
            //console.log(err);

            const asset = await getAsset(mint);
 
            if(asset)
                await displayOwner(asset.ownership.owner);

        }
   


         
      }

      const displayOwner = async(ownerAddress) => {

        const params = {
            address: ownerAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/users/get-username', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    setUsernameJsx(<a href={"/user/" + data.username}>{data.username}</a>);
                }else{
                    //not in the system, so display address to external url 
                    setUsernameJsx(<a target="_blank"  rel="noreferrer" href={'https://explorer.solana.com/address/'+ownerAddress+'?cluster='+ cluster}>{ownerAddress}</a>);

                }
            });
            return;
      }

      const showCollectionLink = async(collectionAddress, collectionName, address, nftName) => {

        const params = {
            address: collectionAddress
        }

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(params)
        };
        fetch(backend_api_url + 'api/v1/machine/get-info-by-mint-address', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 1) {
                     
                    setPreviewCollectionName(<a href={"/collection/" + data.address}>{data.name}</a>);
                    setSymbol(data.symbol);
                    setPreviewImageName(<a target="_blank" className="all-caps"  rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{ data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</a>);
                    //setPreviewImageName(<div>{ data.name.toUpperCase() + ' - ' + nftName.toUpperCase()}</div>);

                }else{
                    //not in the system, so display address to external url 
                    setPreviewCollectionName(<Fragment>{collectionName}</Fragment>);

                }
            });
            return;
      }
    
    const showNftData = (address) => {
        
        //console.log(address);
        //console.log(allNfts);
        let nfts = allNfts === null ? JSON.parse(localStorage.getItem('mintedNfts')) : allNfts;

        if(allNfts) {
            localStorage.removeItem('mintedNfts');
        }
        // console.log(nfts);
        setSymbol("-");

        setUsernameJsx(<Fragment></Fragment>);
        for(var i in nfts) {
            if(nfts[i].address === address) {
                //console.log(nfts[i]);
                setSelectedNftAddress(address);

                //setPreviewCollectionName(nfts[i].collection ? nfts[i].collection : '');


                let nftName = nfts[i].name.split(".")[0];


                if(nfts[i].type === 'nft') {
                    setPreviewImageName(<a target="_blank" className="all-caps" rel="noreferrer" href={'https://explorer.solana.com/address/'+address+'?cluster='+ cluster}>{nftName}</a>);

                } else {
                    //setPreviewImageName(<div>{nftName}</div>);

                    setPreviewImageName(<a target="_blank" className="all-caps" rel="noreferrer" href={'https://translator.shyft.to/address/'+address+'/?cluster='+cluster+'&compressed=true'}>{nftName}</a>);

                }
                
                showCollectionLink(nfts[i].collectionAddress, (nfts[i].collection ? nfts[i].collection : ''), address, nftName);

                setPreviewImageDesc(nfts[i].description);

                
                setPreviewImageUrl(nfts[i].image);

                //setUsernameJsx(<a href={"/user/" + userData1.username}>{userData1.username}</a>);
 
                getMetadataPDA(address);

                if(nfts[i].attributes) {
                    //console.log('show att');
                    const attributes = Array.isArray(nfts[i].attributes) ? nfts[i].attributes : JSON.parse(nfts[i].attributes);
                    //console.log(attributes);
                    const properties = chunkArray(attributes, 2);
                        // console.log(properties);
                    // setPropertiesJsx(attributes.map( (asset : any, index : number) => {
                    //     return asset && <Fragment key={index + (new Date()).getTime()}>
                    //             <div className="custom-column">{asset.trait_type} : {asset.value}</div>
                    //     </Fragment>;
                    // }));

                    const chunkedJSX = [];

                    for (let i = 0; i < properties.length; i ++) {
                        let elem = properties[i];

                        if(elem.length === 2) {
                            chunkedJSX.push(
                                <div className='row-elem' key={i}>
                                    {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                    {elem[1] &&  <div className="custom-column">{elem[1].trait_type}: <span className="prop-value">{elem[1].value}</span></div>} 
                                </div>
                            );
                        }else{
                            chunkedJSX.push(
                                <div className='row-elem' key={i}>
                                    {elem[0] &&  <div className="custom-column">{elem[0].trait_type}: <span className="prop-value">{elem[0].value}</span></div>} 
                                </div>
                            );
                        }
                    }
                    setPropertiesJsx (chunkedJSX);
                } 
                // setPropertiesJsx()
            }
        }

        handleShowPreview();
    // }else{
    //      props.loginWeb3Auth();
    // }
}
 

    const loadAllNftImages = async (uris) => {

        const uploadedNfts = await Promise.all(
            uris.map(async (asset: any) => {
                let resp = false;
                 try {

                    resp = await axios.get(asset.uri).then((response) => {
                        let data = response.data;
                        //console.log(data);
                        data.address = asset.extraInfo.id ? asset.extraInfo.id : asset.address;
                        data.type = asset.extraInfo ? asset.extraInfo.type : 'nft';
                        //console.log(data.image);

                        let collectionName = (response.data.collection ? response.data.collection : 'Collection');
                        if(!response.data.collection && asset.collectionName) {
                            collectionName = asset.collectionName ;

                            //console.log(asset);
                        }
                        data.collection = collectionName;

                        if(data.image) {
                            //data.address = asset.address;
                            data.collectionAddress = asset.collectionAddress;
                            return data;
                        }
                        return false;
                    }).catch((error) => { 
                        console.log(error);
                        toast.error(error.response);
                        return false;
                    });
    
                } catch (err: any) {
                    return null;
                }
                
                return resp;
            })
        );

        var filteredNft = [];
        for(var i in uploadedNfts) {
            if(uploadedNfts[i]) {
                filteredNft.push(uploadedNfts[i])
            }
        }
        // console.log("filteredNft");
        // console.log(filteredNft);

        setNum(filteredNft.length);

        setAllNfts(filteredNft);
        localStorage.setItem('mintedNfts', JSON.stringify(filteredNft));

        setCurrentIndex(0);
        setListingJsx( filteredNft.map( (asset, index) => <Fragment key={index + (new Date()).getTime()}>
                        <a style={{"textAlign": 'center'}} className={props.useCarousel ? 'imgSliderDetails' : ''} href="#NftDetail1" onClick={() => showNftData(asset.address)} data-address={asset.address} >
                        <img alt="" src={asset.image} /><br />{asset.collection ? asset.collection : 'Collection'} - {asset.name.split(".")[0]}</a></Fragment>) );
        
        if(props!.isRefresh) {
            setRenderedElements(filteredNft.map( (asset, index) => <Fragment key={index + (new Date()).getTime()}>
            <a style={{"textAlign": 'center'}} className={props.useCarousel ? 'imgSliderDetails' : ''} href="#NftDetail1" onClick={() => showNftData(asset.address)} data-address={asset.address} >
            <img alt="" src={asset.image} /><br />{asset.collection ? asset.collection : 'Collection'} - {asset.name.split(".")[0]}</a></Fragment>) );
        }
    }
    
      settingsSlider.infinite = num >= 5 ? true : false;
    
      //console.log(settingsSlider);

    return (

        <Fragment>
             {
                props.useCarousel && listingJsx && 
                <Slider  {...settingsSlider}>
                        {
                            listingJsx
                        }

                </Slider>
            } 
            {
                !props.useCarousel && listingJsx && 
                <div className="collectionsDiv1">
                {
                            renderedElements
                        }
                </div>	
            }
            {
                props!.isViewGacha && listingJsx === null && 
                <div className="collectionsDiv">
                    Mint the first NFT for this Collection
                </div>
            }

<Modal className="modal-preview" show={showPreview} onHide={handleClosePreview}
  size="sm"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
        <Modal.Header closeButton>
        <Modal.Title>{previewImageName}</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
                <div id='NftDetail' style={ {"padding" : "10px", "background" : '#ffffff', "margin" : "0px"} }>
                    <img alt="" src={previewImageUrl}/>
                    <br/><br/>
                    <p>OWNED BY : <span>{usernameJsx}</span></p>
                    { previewCollectionName && <Fragment><p className="pull-left">COLLECTION : {previewCollectionName}</p></Fragment>}
 
                    <p className="pull-left">Symbol: {symbol}</p>
                    <p className="pull-left">DESCRIPTION : <span className="full-text" dangerouslySetInnerHTML={{ __html: previewImageDesc }} /></p>
                    

                    <p className="pull-left">PROPERTIES<br/></p>
                    <div className="custom-row">
                        {
                            propertiesJsx ? propertiesJsx : 'N/A'
                        }
                    </div> 
                </div>
        </Modal.Body>
        <Modal.Footer> 
        </Modal.Footer>
    </Modal>
        </Fragment>
    );
}

export default RecentlyMinted;